import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Header from "../components/Header"
import Footer from "../components/Footer"
import LanguageToggle from "../components/LanguageToggle"
import InfoBar from "../components/InfoBar"
import HeroCarousel from "../components/HeroCarousel"
import LoungeInfo from "../components/LoungeInfo"
import LoungeFeatures from "../components/LoungeFeatures"
import LoungeProviders from "../components/LoungeProviders"
import Video from "../components/Video"
import LocationMap from "../components/LocationMap"
import Legal from "../components/Legal"
import TagLine from "../components/TagLine"

export default ({ data }) => {
  const lounge = data.strapiTheCenturionLounge

  // exclude first hero image if it has an internal calm page
  const heroImages = lounge.hasCalm ? lounge.heroImages.slice(1) : lounge.heroImages
  return (
    <Layout>
      <SEO metaTitle={lounge.metaTitle} />
      <Header airportName={lounge.airportName} />
      {lounge.languageLink && lounge.languageUrl &&
        <LanguageToggle languageLink={lounge.languageLink} languageUrl={lounge.languageUrl} />
      }
      <InfoBar />
      {heroImages &&
        <HeroCarousel heroImages={heroImages} />
      }
      <div className="head">
        <div className="head-box">
          <h1>{lounge.airportName}</h1>
        </div>
      </div>
      {lounge.info &&
        <LoungeInfo info={lounge.info} />
      }
      {lounge.features &&
        <LoungeFeatures features={lounge.features} />
      }
      {lounge.providers &&
        <LoungeProviders providers={lounge.providers} showDisclaimer={true} />
      }
      {lounge.video &&
        <Video video={lounge.video} />
      }
      <LocationMap />
      <Legal />
      <TagLine />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
query ($url: String!) {
  strapiTheCenturionLounge(url: {eq: $url}) {
    url
    metaTitle
    airportCode
    airportName
    shortAirportName
    languageLink
    languageUrl
    hasCalm
    heroImages {
      heroImage {
        localFile {
					childImageSharp {
            desktopImage: fluid(maxWidth: 1200, maxHeight: 420) {
              ...GatsbyImageSharpFluid
            }
            mobileImage: fluid(maxWidth: 676, maxHeight: 540) {
              ...GatsbyImageSharpFluid
            }
        }
        }
      }
    }
    info {
      title
      subtitle
      loungeTerminal
      loungeTerminalLetter
      loungeLocation
      alternateTextSize
      description
      smallMapImage {
        localFile {
					childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      largeMapImage {
        localFile {
					childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    features {
      featureImage {
        localFile {
					childImageSharp {
            fluid(maxWidth: 676) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      title
      description
      buttonText
      footnote
      amenities {
        iconId
        name
      }
      menuIntro
      strapiMenu {
        title
        hours
        menuColumns {
          menuItems {
            title
            item
          }
        }
        footnote
        creatorTitle
        creatorDescription
        creatorImage {
          localFile {
						childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    providers {
      name
      logo {
        localFile {
					childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      description
      linkText
      linkUrl
    }
    video {
      videoTitle
      videoDescription
      youTubeVideoID
    }
  }
}
`