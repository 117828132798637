import React from "react"

const TagLine = ({ tagline }) => {
  return (
    <div className="container container--narrow island text-center">
      <div className="tagline"></div>
    </div>
  )
}

export default TagLine
